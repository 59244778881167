<template>
  <VCard class="px-2 py-2 mb-2">
    <VRow class="align-center pa-2" no-gutters justify="space-between">
      <VCol cols="auto" class="text-left">
        {{ $t("landing.adminPanel.title") }}
      </VCol>

      <VCol cols="auto" class="text-right">
        <VBtn
          depressed
          @click="createDialog = true"
          class="success-bg text-white text-transform-none br-10"
        >
          <VIcon :left="!$vuetify.breakpoint.xs">mdi-plus</VIcon>
          <div v-if="!$vuetify.breakpoint.xs">
            {{ $t("landing.btn.createRequest") }}
          </div>
        </VBtn>
      </VCol>
    </VRow>
    <VDivider />
    <div>
      <!-- start -->
      <v-simple-table class="my-table" :dense="!isLarge">
        <template v-slot:default>
          <thead>
            <RequestFilters :filter="filter" :states="states" />
          </thead>
          <tbody name="fade" is="transition-group">
            <request-row
              :states="states"
              :request="request"
              v-for="request in requests"
              :key="request.uuid"
              @toggleRow="toggleRow"
              :class="{ 'active-row': activeRow == request.uuid }"
            />
          </tbody>
        </template>
      </v-simple-table>
      <v-progress-linear v-if="isLoadingFilter" indeterminate color="primary"></v-progress-linear>
      <h3 v-if="!requests.length" class="text-center">{{ $t("table.noData") }}</h3>
      <div
        v-observer="{ nextPage: nextPage }"
        class="observer"
        v-if="!loadMoreDisabled && !loading"
      ></div>
    </div>
    <RequestDialog type="create" :states="states" :visible="createDialog" @close="onCloseCreateRequest" />
  </VCard>
</template>

<script>
import RequestFilters from "./request-filters/requestFilters.vue";
import SizeUi from "@/mixins/SizeUi";
import loader from "@/mixins/loader";
import joinRequestService from "@/services/request/joinRequest/joinRequestService";
import RequestRow from "./request-content/requestRow.vue";
import depotService from "@/services/request/depot/depotService";
import RequestDialog from "./dialogs/RequestDialog.vue";
import EventBus from "@/events/EventBus";
import newRequests from '@/mixins/newRequestsMixin'

export default {
  async mounted() {
    EventBus.$on("requests-modified", async () => {
      this.page = 1
      await this.fetchRequests();
      await this.fetchAmount()
    });
    this.setLoading(true);
    await this.fetchRequests();
    await this.getStates();
    await this.fetchAmount()
  },
  methods: {
    onCloseCreateRequest() {
      this.createDialog = false;
    },
    async getStates() {
      this.states = await depotService.getAllStates({
        type: "JOIN_REQUEST"
      });
    },
    toggleRow(e) {
      this.activeRow = e;
    },
    async nextPage() {
      this.page += 1;
      // this.setLoading(true);
      this.isLoadingFilter = true;
      await this.fetchRequests(this.inputFilter, true, true);
      this.isLoadingFilter = false;
    },
    async fetchRequests(params = {}, next = false, uselike = false, updated) {
      console.log(next)
      try {
        params.offset = updated ? 0 : (this.page - 1) * this.perPage;
        params.limit = updated ? this.largeLimit : this.perPage;
        this.loading = true;
        const newItems = await joinRequestService.getJoinRequestList({
          ...params,
          order: "DESC",
          orderby: "time_created"
        });

        if (next) {
          this.requests.push(...newItems);
        } else {
          this.requests = newItems;
        }

        this.setLoading(false);
        this.loading = false;
        this.loadMoreDisabled = newItems.length < this.perPage;
      } catch (e) {
        this.loading = false;
        this.setLoading(false);
        this.setErrorNotification(e);
      }
    }
  },
  watch: {
    filter: {
      deep: true,
      async handler() {
        const params = {
          uselike: true
        };
        Object.keys(this.filter).forEach(key => {
          if (this.filter[key]) {
            params[key] = this.filter[key];
          }
        });
        this.inputFilter = params;
        // orderDialog(this.filter)
        if (params.phone) {
          params.phone = params.phone.replace(/[^+\d]/g, "");
        }
        console.log(params);
        this.page = 1;
        await this.fetchRequests(params, false, true);
      }
    }
  },
  computed: {
    rowHeight() {
      if (this.isLarge) {
        return 48;
      } else {
        return 35;
      }
    },
    perPage() {
      return Math.ceil(window.innerHeight / this.rowHeight);
    },
    largeLimit() {
      const pageLimit = this.perPage * this.page;
      if (pageLimit >= 200) {
        this.page = this.pageBeforeLimit - 1;
        return 200;
      }
      return this.perPage * this.page;
    },
    nextPageDisabled() {
      return this.requests.length < this.perPage;
    }
  },
  components: { RequestFilters, RequestRow, RequestDialog },
  mixins: [SizeUi, loader, newRequests],
  data: () => ({
    states: [],
    requests: [],
    loadMoreDisabled: false,
    loading: false,
    isLoadingFilter: false,
    createDialog: false,
    page: 1,
    inputFilter: {},
    filter: {
      name: "",
      phone: "",
      description: "",
      email: ""
    },
    activeRow: null
  })
};
</script>

<style></style>
