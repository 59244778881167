<template>
  <tr>
    <th class="icon text-center"><VIcon dense>mdi-cogs</VIcon></th>
    <th class="text-left">
      <div class="header">
        <span>{{ $t("table.name") }}</span>
        <VTextField dense hide-details solo v-model="filter.name" clearable />
      </div>
    </th>
    <th class="text-left">
      <div class="header">
        <span>{{ $t("table.phone") }}</span>
        <VTextField
          v-on:keypress="onlyNumber($event)"
          dense
          hide-details
          autocomplete="new-password"
          solo
          v-model="filter.phone"
          clearable
        />
      </div>
    </th>
    <th>
      <div class="header">
        <span>{{ $t("table.email") }}</span>
        <VTextField dense hide-details solo autocomplete="new-password" v-model="filter.email" clearable />
      </div>
    </th>
    <th>
      <div class="header">
        <span>{{ $t("table.status") }}</span>
        <VSelect
          dense
          :placeholder="$t('btn.all')"
          :items="Object.values(states)"
          :item-text="getStatusText"
          :item-value="'id'"
          hide-details
          solo
          v-model="filter.id_state"
          class="text-caption"
          clearable
        >
          <template v-slot:item="{ item }">
            <div class="d-flex align-center justify-content-start ">
              <StatusCircle :status-name="item.id" :type="'supply'" class="pb-1" />
              <div class="elispsis">
                {{ getStatusText(item) }}
              </div>
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <div class="d-flex align-center justify-content-start ">
              <StatusCircle :status-name="item.id" :type="'supply'" class="pb-1" />
              <div class="elispsis">
                {{ getStatusText(item) }}
              </div>
            </div>
          </template>
        </VSelect>
      </div>
    </th>
    <th class="text-left ">
      <div class="header mt-4">
        <span>{{ $t("table.createdData") }}</span>
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="-103"
          transition="scale-transition"
          offset-y
          min-width="auto"
          class="text-caption"
        >
          <template v-slot:activator="{ on, attrs }">
            <VTextField
              class="bg-white"
              v-model="filter.time_created"
              readonly
              append-icon="mdi-calendar"
              solo
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
              clearable
            />
          </template>
          <v-date-picker
            :locale="currentLanguage.key"
            no-title
            :max="NextYear"
            min="2021"
            v-model="filter.time_created"
            @input="dateMenu = false"
          ></v-date-picker>
        </v-menu>
      </div>
    </th>
    <th class="text-left ">
      <div class="header mt-4">
        <span>{{ $t("table.updatedData") }}</span>
        <v-menu
          v-model="dateMenuUpdated"
          :close-on-content-click="false"
          :nudge-right="-103"
          transition="scale-transition"
          offset-y
          min-width="auto"
          class="text-caption"
        >
          <template v-slot:activator="{ on, attrs }">
            <VTextField
              class="bg-white"
              v-model="filter.time_updated"
              readonly
              append-icon="mdi-calendar"
              solo
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
              clearable
            />
          </template>
          <v-date-picker
            :locale="currentLanguage.key"
            no-title
            :max="NextYear"
            min="2021"
            v-model="filter.time_updated"
            @input="dateMenuUpdated = false"
          ></v-date-picker>
        </v-menu>
      </div>
    </th>
    <th>
      <div class="header">
        <span>{{ $t("table.created") }}</span>
        <VTextField dense hide-details solo v-model="filter.user_details__name" clearable />
      </div>
    </th>
  </tr>
</template>

<script>
import { onlyNumber } from "@/mixins/helpers";
import { mapGetters } from "vuex";
import StatusCircle from "@/components/move-list/StatusCircle.vue";

export default {
  methods: {
    onlyNumber,
    getStatusText(item) {
      return item[`${this.currentLanguage.key}_name`];
    }
  },
  props: {
    filter: {
      required: true
    },
    states: {
      required: true
    }
  },
  data: () => ({
    dateMenu: false,
    dateMenuUpdated:false,
  }),
  components: {
    StatusCircle
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    NextYear() {
      const event = new Date().toISOString().substr(0, 12);
      return event;
    }
  }
};
</script>

<style></style>
