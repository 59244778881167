<template>
    <JoinRequestsComponent />
  </template>
  
  <script>
  import JoinRequestsComponent from "@/components/join-requests/JoinRequestsComponent.vue";
  
  export default {
    name: 'JoinRequests',
    components: {
        JoinRequestsComponent,
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  